//sign up with email or google
//add that user to firestore users collection
import { AuthProps } from "./auth";
import { useState, useEffect } from "react";
import { createUserWithEmailAndPassword, getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword} from "firebase/auth";
import { User, onAuthStateChanged } from "@firebase/auth";
import { addUser } from "../../firebase/firestore/db";
import { AmcilarUser } from "../../firebase/firestore/firestore-types";
import { CollectionReference, Timestamp } from "firebase/firestore";
import { Navigate, To, redirect , useNavigate} from "react-router-dom";
import { FirebaseError } from "firebase/app";
import { useAuth } from "../../contexts/authContext";
import "../../styles-common/auth.css"
//set up firebase auth signup logic with firestore user addition.


const auth = getAuth();

const SignUpPage = (props: AuthProps) => {
    const [user, setUser] = useState<User | null>(null);
    const [displayName, setDisplayName] = useState<string>(" ");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const [uid, setUid] = useState<string | null>(null);

    const handleLogin = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            // Successful login - Redirect or do other actions
        } catch (error) {
            setError((error as Error).message);
            setUid(null);
        }
    };

    const handleFormSubmission = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            );
            setUser(userCredential.user);
            console.log("New user created:", user);

            onAuthStateChanged(auth, (user) => {
                if (user) {
                    console.log("user is signed in");
                    const currentUser = auth.currentUser;
                    setUser(currentUser);
                    const newUid = currentUser?.uid;
                    setUid(newUid ?? null);
                    const timestampnow = Timestamp.now();
                    //add user to firestore users collection
                    const amcilarUser: AmcilarUser = {
                        uid: newUid as string,
                        displayName: currentUser?.providerData[0].displayName as string,
                        alias: displayName,
                        email: currentUser?.providerData[0].email as string,
                        photoURL: "",
                        purchaseHistory: [],
                        address: "",
                        paymentInfo: [],
                        createdAt: timestampnow,
                        updatedAt: timestampnow,
                        providerId: ""
                    };
                    addUser(amcilarUser as AmcilarUser).then((docRefId) => {
                        console.log("user added to firestore users collection with id: ", docRefId);
                    });

                } else {
                    // User is signed out
                }
            });
        } catch (error) {
            // If the error is due to email already existing, try login
            if ((error as any)?.code === "auth/email-already-in-use") {
                setError(null);
                handleLogin();
            } else {
                setError((error as Error).message);
                setUid(null);
            }
        }
    };

    return (
        <div className="signup-page-wrapper">
            {/* sign up form  with email and password*/}
            <div className="container-login100">
                <div className="wrap-login100">
                    <form className="login100-form validate-form">
                        <span className="login100-form-title p-b-26">
                            Hoş Geldin.
                        </span>
                        <span className="login100-form-title p-b-48">
                            <i className="zmdi zmdi-font"></i>
                        </span>
                        <div className="wrap-input100 validate-input" data-validate="Valid email is: a@b.c">
                            <input className="input100" type="text" value={displayName}
                            onChange={(e) => {e.preventDefault(); setDisplayName(e.target.value)}} />
                            <span className="focus-input100" data-placeholder="Ad ve Soyad"></span>
                        </div>
                        <div className="wrap-input100 validate-input" data-validate="Valid email is: a@b.c">
                            <input className="input100" type="email" value={email}
                            onChange={(e) => {e.preventDefault(); setEmail(e.target.value)}}/>
                            <span className="focus-input100" data-placeholder="Email"></span>
                        </div>
                        <div className="wrap-input100 validate-input" data-validate="Enter password">
                            <span className="btn-show-pass">
                                <i className="zmdi zmdi-eye"></i>
                            </span>
                            <input className="input100" type="password" value={password}
                            onChange={(e) => {e.preventDefault(); setPassword(e.target.value)}} />
                            <span className="focus-input100" data-placeholder="Şifre"></span>
                        </div>
                        <div className="container-login100-form-btn">
                            <div className="wrap-login100-form-btn">
                                <div className="login100-form-bgbtn"></div>
                                <button type="submit" className="login100-form-btn" onClick={handleFormSubmission}>
                                    Giriş Yap
                                </button>
                            </div>
                        </div>
                        <div className="text-center p-t-115">
                            <span className="txt1">
                                Hesabın yok mu? &nbsp;
                            </span>
                            <a className="txt2" href="#">
                                 Kayıt Ol
                            </a>
                            <br />
                            <span className="txt1">
                                Veya
                            </span>
                            <br />
                            {/* sign up with google */}
                            
                        </div>
                    </form>
                    <button className="signin-google-button" onClick={() => {
                                const provider = new GoogleAuthProvider();
                                signInWithPopup(auth, provider).then(async (result) => {
                                    // if (result.user.metadata.lastSignInTime === result.user.metadata.creationTime) {
                                    //     console.log("user signed in with google");
                                    //     redirect("/");
                                    // }
                                    const credential = GoogleAuthProvider.credentialFromResult(result);
                                    const token = credential?.accessToken;
                                    const user = result.user;
                                    const timestampnow = Timestamp.now();
                                    console.log(user);
                                    console.log(token);
                                    //add user to firestore users collection
                                    const amcilarUser: AmcilarUser = {
                                        uid: user?.uid as string,
                                        displayName: user?.providerData[0].displayName as string,
                                        alias: user?.providerData[0].displayName as string,
                                        email: user?.providerData[0].email as string,
                                        photoURL: user?.providerData[0].photoURL as string,
                                        purchaseHistory: [],
                                        address: "",
                                        paymentInfo: [],
                                        createdAt: timestampnow,
                                        updatedAt: timestampnow,
                                        providerId: ""
                                    };
                                    await addUser(amcilarUser as AmcilarUser).then((docRefId) => {
                                        console.log("user added to firestore users collection with id: ", docRefId);
                                        //redirect user to / redirect() doesn't work here

                                    });
                                }).catch((error) => {
                                    const errorCode = error.code;
                                    const errorMessage = error.message;
                                    const email = error.email;
                                    const credential = GoogleAuthProvider.credentialFromError(error);
                                    console.log(errorCode);
                                    console.log(errorMessage);
                                    console.log(email);
                                    console.log(credential);
                                });
                            }
                        }> <img src='https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_"G"_logo.svg'/> &nbsp;&nbsp; Google ile Giriş Yap</button>
                </div>
                
            </div>
        </div>
    )
};

export default SignUpPage;