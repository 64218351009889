import './App.css';
import {useMemo} from 'react';
import { Route, Routes } from 'react-router-dom';
import { User } from "firebase/auth";
import AppFooter from './modules/general-components/AppFooter';
import NotFoundPage from './common-pages/notFoundPage';
import ShopPage from './subdomains/shop/shop';
import HomePage from './subdomains/home/home';
import AuthPage from './common-pages/auth/auth';
import CheckoutPage from './subdomains/shop/checkout';
import CartPage from './subdomains/shop/cart';
import ProductPage from './subdomains/shop/product';
import { AuthProvider } from './contexts/authContext';
import Products from './subdomains/shop/products';
import { Header } from './modules/general-components/header';
import { GoodFooter } from './modules/general-components/goodfooter';
import { useAuth } from './contexts/authContext';
import { ManageProducts } from './subdomains/shop/admin/manageProducts';

export interface AppProps {
  user: User | null;
}

function App(props: { user: User | null }) {
  //load user
  var host = window.location.host
  console.log(host)
  var subdomain = host.split('.')[0]
  if (subdomain === 'merch' || subdomain === "shop" || subdomain === "store") {
    return (
      <>
        <AuthProvider>
        <Header/>
          <Routes>
            <Route path="/" element={<ShopPage user={props.user} />} />
            {/* <Route path="/auth" element={<AuthPage user={props.user}/>} /> */}
            <Route path="/products/*" element={<Products user={props.user} />} />
            <Route path="/auth/*" element={<AuthPage user={props.user} />} />
            <Route path="/product/giyim/*" element={<ProductPage user={props.user} />} />
            <Route path="/product/accessory/*" element={<ProductPage user={props.user} />} />
            <Route path="/products/category/giyim/*" element={<ShopPage user={props.user} />} />
            <Route path="/proucts/category/accessory/*" element={<ShopPage user={props.user} />} />
            <Route path="/checkout/*" element={<CheckoutPage user={props.user} />} />
            <Route path="/cart/*" element={<CartPage user={props.user} />} />
            <Route path="/*" element={<NotFoundPage user={props.user} />} />
            <Route path="/admin" element={<ManageProducts user={props.user} />} />
          </Routes>
          {/* <div className="shop-footer" style={{position:"relative", bottom:"0", left:"0", width:"100%"}}>
            <AppFooter/>
          </div> */}
          <GoodFooter/>
        </AuthProvider>
      </>
    );
  } else if (subdomain === 'home') {
    return (
      <>
        <AuthProvider>
          <Header/>
          <Routes>
            <Route path="/" element={<HomePage user={props.user} />} />
            <Route path="/auth" element={<AuthPage user={props.user} />} />
            <Route path="/auth/*" element={<AuthPage user={props.user} />} />
            <Route path="/*" element={<NotFoundPage user={props.user} />} />
          </Routes>
          {/* <div className="shop-footer" style={{ position: "relative", bottom: "0", left: "0", width: "100%" }}>
            <AppFooter />
          </div> */}
          <GoodFooter/>
        </AuthProvider>
      </>
    );
  } else {
    return (
      <>
        <AuthProvider>
          <Header/>
          <Routes>
            <Route path="/" element={<ShopPage user={props.user} />} />
          </Routes>
          {/* <div className="shop-footer" style={{ position: "relative", bottom: "0", left: "0", width: "100%" }}>
            <AppFooter />
          </div> */}
          <GoodFooter/>
        </AuthProvider>
      </>
    );
  }
}

export default App;
