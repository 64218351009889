import react, { useEffect, useState } from 'react';
import { User } from 'firebase/auth';
import { HomeProps } from './home';
import { getHallOfFameElements } from '../../firebase/firestore/db';
import { HOFElement } from '../../firebase/firestore/firestore-types';
import '../../styles-common/hof.css';
//hall of fame

const HOFPage = (props: HomeProps) => {
  const [hofElements, setHofElements] = useState<HOFElement[]>([]);
  const handleGetHOFElements = async () => {
    const hofElements = await getHallOfFameElements() as HOFElement[];
    setHofElements(hofElements);
    console.log(hofElements);
  };
  useEffect(() => {
    handleGetHOFElements();
  }, []);
  return (
    <div className="hof-page-wrapper">
      <h1>Hall of Fame</h1>
      {/* //TODO: implement hall of fame page with a list of users with the highest purchase-history.length.
      display cards with profile pictures from firestore database users/{userId}'s profilePictureUrl field.
      on top of them display their rank, and under the profile pictures, display their alias, under that their description.
      */}
      <div className="hof-page-user-list">
        {hofElements.map((hofElement, index) => {
          return (
            <div className='hof-page-user-list-user'>
              <div className='hof-page-user-list-user-rank'>{hofElement.rank}</div>
              <div className='hof-page-user-list-user-profile-picture'>
                <img src={hofElement.imageUrl} alt='profile-pic' />
              </div>
              <div className='hof-page-user-list-user-alias'>{hofElement.displayName}</div>
              <div className='hof-page-user-list-user-description'>{hofElement.description}</div>
            </div>
          );
        })}
        <div className='hof-page-user-list-user'>user2</div>
      </div>
    </div>
  );
};

export default HOFPage;