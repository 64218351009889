import React, { useState, useEffect } from 'react';
import { User } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { getAmcilarUser, getAllProducts, addProduct as addProductToFirestore } from '../../../firebase/firestore/db';
import { Product } from '../../../firebase/firestore/firestore-types';

export const ManageProducts = (props: { user: User | null }) => {
    const [userAdmin, setUserAdmin] = useState<boolean>(false);
    const [product, setProduct] = useState<Partial<Product>>({});
    const [products, setProducts] = useState<Product[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (props.user) {
            getAmcilarUser(props.user.uid as string).then((user) => {
                setUserAdmin(!!user?.isAdmin);
            });
        }
    }, [props.user]);

    useEffect(() => {
        const handleGetProducts = async () => {
            setIsLoading(true);
            const fetchedProducts = await getAllProducts();  // Assume fetchProducts is a function that fetches products from Firestore
            if (fetchedProducts) {
                setProducts(fetchedProducts);
            }
            setIsLoading(false);
        };

        handleGetProducts();
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setProduct((prevProduct) => ({
            ...prevProduct,
            [name]: name === 'productSizes' || name === 'imageUrls' ? value.split(',').map((item) => item.trim()) : value
        }));
    };

    const handleAddProduct = async () => {
        if (product) {
            const newProduct: Product = {
                ...product,
                pricePerUnitTry: product.pricePerUnitTry ? parseFloat(product.pricePerUnitTry.toString()).toFixed(2) : '0.00' as unknown as number,
                productId: product.productName!.replace(/\s/g, '') + Date.now().toString(),
                createdAt: Timestamp.now(),
                updatedAt: Timestamp.now(),
                ratings: [],
                averageRating: 0,
                comments: []
            } as Product;
            await addProductToFirestore(newProduct.category, newProduct);
            setProducts([...products, newProduct]);
            setProduct({});
        }
    };

    if (!props.user) {
        return <></>;
    }
    if (!userAdmin) {
        return <h1>Unauthorized</h1>;
    }
    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>Manage Products</h1>
            <div className='add-product'>
                <h2>Add Product</h2>
                <input type="text" name="productName" placeholder="Product Name" value={product.productName || ''} onChange={handleInputChange} />
                <textarea name="description" placeholder="Description" value={product.description || ''} onChange={handleInputChange} />
                <input type="text" name="category" placeholder="Category" value={product.category || ''} onChange={handleInputChange} />
                <input type="number" name="pricePerUnitTry" placeholder="Price" value={product.pricePerUnitTry || ''} onChange={handleInputChange} />
                <input type="text" name="imageUrls" placeholder="Image URLs (seperate ,)" value={product.imageUrls?.join(', ') || ''} onChange={handleInputChange} />
                <input type="text" name="productSizes" placeholder="Sizes (seperate ,)" value={product.productSizes?.join(', ') || ''} onChange={handleInputChange} />
                <button onClick={handleAddProduct}>Add Product</button>
            </div>
            <div className='manage-products'>
                <h2>Manage Products</h2>
                {products && products.map((product, index) => (
                    <div key={index}>
                        <h3>{product.productName}</h3>
                        <p>{product.description && product.description}</p>
                        <p>{product.category}</p>
                        <p>{product.pricePerUnitTry && product.pricePerUnitTry}</p>
                        <p>{product.imageUrls || ""}</p>
                        <p>{product.productSizes?.join(",") || ""}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}