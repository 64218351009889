import React, { useEffect, useState } from 'react';
import "../../styles-common/home.css"
import { User } from 'firebase/auth';
import HOFPage from './hof';
export interface HomeProps {
    user: User | null;
}
const HomePage = (props: HomeProps) => {

    return (
        <div className='home-page-wrapper'>
            {/* set up a home page */}
            <h1>Home Page</h1>
            <div>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem ratione error aut, explicabo praesentium rerum, in excepturi sequi commodi voluptatibus distinctio voluptates! Eum voluptatibus magni nobis minima necessitatibus dolorem repellat.
                <br />
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem ratione error aut, explicabo praesentium rerum, in excepturi sequi commodi voluptatibus distinctio voluptates! Eum voluptatibus magni nobis minima necessitatibus dolorem repellat.
                
            </div>
            <HOFPage user={props.user} />
        </div>
    );
};

export default HomePage;