import React, { useEffect, useMemo, useState } from 'react';
import { User } from 'firebase/auth';
import '../../styles-common/cart.scss';
import { getCartItemsOfUser, getAllCartItemProductsOfAllCategoriesOfUser, updateCartItem , deleteCartItem} from '../../firebase/firestore/db';
import { CartItem, Product } from '../../firebase/firestore/firestore-types';
import { useAuth } from '../../contexts/authContext';
import AuthPage from '../../common-pages/auth/auth';

export interface CartProps {
    user: User | null;
}

export default function CartPage(props: CartProps) {
    const user = useAuth();  // Destructure to get user directly
    const [userUid, setUserUid] = useState<string | null>(null);
    const [productsFromCart, setProductsFromCart] = useState<Product[]>([]);
    const [cart, setCart] = useState<CartItem[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    
    useMemo(() => {
        if (user) {
            setUserUid(user.uid);
        }
    }, [user]);

    useMemo(() => {
        const handleGetCart = async () => {
            if (userUid) {
                try {
                    const cartItems = await getCartItemsOfUser(userUid);
                    setCart(cartItems as CartItem[]);
                    const products = await getAllCartItemProductsOfAllCategoriesOfUser(userUid);
                    setProductsFromCart(products as Product[]);
                } catch (error) {
                    console.error('Error fetching cart data:', error);
                } finally {
                    setIsLoading(false);  // Set loading to false after data is fetched
                }
            } 
        };

        handleGetCart();
    }, [userUid]);

    const handleQuantityChange = async (cartItemId: string, newQuantity: number) => {
        if (userUid) {
            try {
                await updateCartItem(userUid, cartItemId, newQuantity);
                setCart((prevCart) =>
                    prevCart?.map((item) =>
                        item.productId === cartItemId ? { ...item, quantity: newQuantity } : item
                    ) || null
                );
            } catch (error) {
                console.error('Error updating cart item quantity:', error);
            }
        }
    };

    const handleRemoveItem = async (cartItemId: string) => {
        if (userUid) {
            try {
                await deleteCartItem(userUid, cartItemId);
                setCart((prevCart) =>
                    prevCart?.filter((item) => item.productId !== cartItemId) || null
                );
            } catch (error) {
                console.error('Error deleting cart item:', error);
            }
        }
    };

    if (!user) {
        return <>
            {/* <div className="cartloginrequired">sa</div> */}
            <AuthPage user={null} />;
        </>
    }

    if (isLoading) {
        return <div>Sepetiniz Yükleniyor...</div>;  // Display loading state while fetching data
    }

    

    return (
        <div className="cart-page-wrapper">
            <div className="shopping-cart">
                <h1 className='carth1'>Bunlar yakında sizin olacak, {user.displayName}</h1>
                <hr />
                <div className="column-labels">
                    <label className="product-image">Görsel</label>
                    <label className="product-details">Ürün</label>
                    <label className="product-price">Fiyat</label>
                    <label className="product-quantity">Miktar</label>
                    <label className="product-line-price">Toplam</label>
                </div>
                {cart && cart.length > 0 ? (
                    <>
                        {cart.map((cartItem, index) => (
                            <div className="product" key={index}>
                                {productsFromCart[index] && (
                                    <>
                                        <div className="product-image">
                                            <img src={productsFromCart[index].imageUrls[0]} alt={productsFromCart[index].productName} />
                                        </div>
                                        <div className="product-details">
                                            <div className="product-title">{productsFromCart[index].productName}</div>
                                            <p className="product-description">{productsFromCart[index].description}</p>
                                        </div>
                                        <div className="product-price">{productsFromCart[index].pricePerUnitTry}</div>
                                        <div className="product-quantity">
                                            <button className='qttdown' onClick={() => handleQuantityChange(cartItem.productId, cartItem.quantity - 1)}>-</button>
                                            <input 
                                                type="number" 
                                                value={cartItem.quantity} 
                                                min="1" 
                                                max="99" 
                                                onChange={(e) => handleQuantityChange(cartItem.productId, parseInt(e.target.value))}
                                            />
                                            <button className='qttup' onClick={() => handleQuantityChange(cartItem.productId, cartItem.quantity + 1)}>+</button>
                                        </div>
                                        <div className="product-line-price">{(productsFromCart[index].pricePerUnitTry * cartItem.quantity).toFixed(2)}</div>
                                        <div className="product-removal">
                                            <button onClick={()=>handleRemoveItem(cartItem.productId)} className="remove-product">Remove</button>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                        <div className="totals">
                            <div className="totals-item">
                                <label>Ara Toplam</label>
                                <div className="totals-value" id="cart-subtotal">{cart.reduce((total, item, index) => total + (productsFromCart[index]?.pricePerUnitTry || 0) * item.quantity, 0).toFixed(2)}</div>
                            </div>
                            <div className="totals-item">
                                <label>Vergi (10%)</label>
                                <div className="totals-value" id="cart-tax">{(cart.reduce((total, item, index) => total + (productsFromCart[index]?.pricePerUnitTry || 0) * item.quantity, 0) * 0.10).toFixed(2)}</div>
                            </div>
                            <div className="totals-item">
                                <label>Kargo</label>
                                <div className="totals-value" id="cart-shipping">0,00</div>
                            </div>
                            <div className="totals-item totals-item-total">
                                <label>Net Toplam</label>
                                <div className="totals-value" id="cart-total">{(cart.reduce((total, item, index) => total + (productsFromCart[index]?.pricePerUnitTry || 0) * item.quantity, 0) * 1.10).toFixed(2)}</div>
                            </div>
                        </div>

                        <button className="checkout">Checkout</button>
                    </>
                ) : (
                    <p>Sepetinde hiçbir ürün yok. <a href="/"> Git doldur!</a></p>
                )}
            </div>
        </div>
    );
}
