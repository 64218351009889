import * as react from 'react';
import { app } from '../../configs/firebaseConfig';
import { User, getAuth, signInAnonymously , signOut} from "firebase/auth";
import firebase from "firebase/app";
import { firebaseConfig } from "../../configs/firebaseConfig";
import { Route, Routes } from 'react-router-dom';
import AppFooter from '../../modules/general-components/AppFooter';
import SignInPage from './signin';
import SignUpPage from './signup';
import NotFoundPage from '../notFoundPage';
import '../../styles-common/auth.css';
import { auth, useAuth } from '../../contexts/authContext';
import { Helmet } from 'react-helmet';
export interface AuthProps {
  user : User |any;
}

export const logOut = async () => {
  await signOut(auth)
}

const IDontCareAboutFirebaseAuth = () => {
  var user = useAuth();
  return (
    <>
    <Helmet>
      <title>{true ? "Log In" : "Sign Up"}</title>
    </Helmet>
    {user?.displayName && <><p>Şu anda {user?.displayName} olarak giriş yapmış bulunmaktasınız</p>
      <button onClick={() => {
        logOut().then(()=>{
          console.log("logged out of account:", user?.displayName);
          user = null;
        })
        }}>log out</button>
    </>}
    
    </>
  );
};

const AuthPage = (props: AuthProps) => {
  const user = useAuth();
  if (user) {
    return (
      <div className='auth-page-wrapper' style={{fontSize:"2em"}}>
        Şu anda {user.displayName} olarak giriş yapmış bulunmaktasın. <br />
        <a href='/'>Anasayfa'ya</a> gitmeye ne dersin? <br />
        Veya <a href="#" onClick={() => {
          logOut().then(()=>{
            console.log("logged out of account:", user.displayName);
          })
          }}>Çıkış Yap</a>
      </div>
    );
  } else {
    return (
      <div className='auth-page-wrapper'>
        <Routes>
          <Route path="/" element={<SignUpPage user={user} />} />
        </Routes>
        <IDontCareAboutFirebaseAuth />
      </div>
    );
  }
};

export default AuthPage;