import { getAnalytics } from 'firebase/analytics';
import { firebaseConfig } from '../../configs/firebaseConfig';
import { initializeApp } from 'firebase/app';
// import { } from './firestore-types';
import { getFirestore, collection, doc, addDoc, getDoc, getDocs, query, where, DocumentReference, deleteDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { CartItem, HOFElement, Product , AmcilarUser} from './firestore-types';
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

// Firestore
const categories = ["giyim", "accessory"];

//add user to users collection after signing up if it doesn't exist already (check uid field)
export const addUser = async (userr: AmcilarUser) => {
    try {
        const colRef = collection(db, "users");
        const docSnap = await getDocs(colRef);
        if (docSnap.docs.length > 0) {
            const data = docSnap.docs.map(doc => doc.data());
            const userExists = data.find((user) => user.uid === userr.uid);
            if (userExists) {
                console.log("db.ts 'addUser': user already existsin db");
                return "userexists";
            } else {
                await addDoc(colRef, userr).then((docRef) => {
                    console.log("Document written with ID: ", docRef.id);
                    return docRef.id as string;
                });
            }
        } else {
            await addDoc(colRef, userr).then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
                return docRef.id as string;
            });
        }
    } catch (error) {
        console.error("Error adding document: ", error);
    }
}

export const getAmcilarUser = async (userId: string) => {
    const collectionRef = collection(db, "users");
    const q = query(collectionRef, 
        where("uid", "==", userId));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.size === 0) {
        console.log("db.ts 'getAmcilarUser' : querySnapshot.size === 0 (no such document)");
        return null;
    } else if (querySnapshot.size !== 1) {
        console.log("db.ts 'getAmcilarUser' : querySnapshot.size > 1 (more than one document)");
        return null;
    } else {
        return querySnapshot.docs[0].data() as AmcilarUser;
    }
}

//get product based on subcollection name 
export const getAllProductsOfCategory = async (subCollectionNameCategory: string ) => {
    try {
      const colRef = collection(db, "shop", "products", subCollectionNameCategory);
      const docSnap = await getDocs(colRef);
      if (docSnap.docs.length > 0) {
        const data = docSnap.docs.map(doc => doc.data());
        return data as Product[];
      } else {
        console.log("db.ts 'getAllProductsOfCategory': no product was found");
        return null;
      }
    } catch (error) {
      console.error('Error fetching Subjects:', error);
    }
  };

//get a single product from shop/products/subCollectionNameCategory/ document that has the a field "productId" with value "productId
export const getProduct = async (subCollectionNameCategory: string, productId: string) => {
    const collectionRef = collection(db, "shop", "products", subCollectionNameCategory);
    const q = query(collectionRef, 
        where("productId", "==", productId));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.size === 0) {
        console.log("db.ts 'getProduct' : querySnapshot.size === 0 (no such document)");
        return null;
    } else if (querySnapshot.size !== 1) {
        console.log("db.ts 'getProduct' : querySnapshot.size > 1 (more than one document)");
        return null;
    } else {
        return querySnapshot.docs[0].data() as Product;
    }
}

//get all products from shop/products/ every single subcollection/ every signle document. and return it as an array of products
export const getAllProducts = async () => {
    try {
        //get products from giyim and accessory subcollections, and return them all as an array of products
        // const categories = ["giyim", "accessory"];
        const products: Product[] = [];
        for (const category of categories) {
            const colRef = collection(db, "shop", "products", category);
            const docSnap = await getDocs(colRef);
            if (docSnap.docs.length > 0) {
                const data = docSnap.docs.map(doc => doc.data());
                products.push(...((data as Product[])));
                
            } else {
                console.log("db.ts 'getAllProducts': no product was found");
                return null;
            }
            
        }
        return (products as Product[]);
    } catch (error) {
        console.error('Error fetching Subjects:', error);
    }
}

//get a single product from shop/products/ scan every single subcollection/ scan every signle document. return the first product that has the a field "productId" with value "productId
export const getProductFromAllWithId = async (productId: string) => {
    try {
        //get products from giyim and accessory subcollections, and return them all as an array of products
        for (const category of categories) {
            const colRef = collection(db, "shop", "products", category);
            const q = query(colRef, 
                where("productId", "==", productId));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.size === 0) {
                console.log("db.ts 'getProductFromAllWithId' : querySnapshot.size === 0 (no such document)");
                return null;
            } else if (querySnapshot.size !== 1) {
                console.log("db.ts 'getProductFromAllWithId' : querySnapshot.size > 1 (more than one document)");
                return null;
            } else {
                return querySnapshot.docs[0].data() as Product;
            }
        }
    } catch (error) {
        console.error('Error fetching Subjects:', error);
    }
}

export const addProduct = async (subCollectionNameCategory: string, product: Product) => {
    try {
        const docRef = await addDoc(collection(db, "shop", "products", subCollectionNameCategory), product as Product);
        console.log("Document written with ID: ", docRef.id);
        return docRef.id;
    } catch (error) {
        console.error("Error adding document: ", error);
    }
}

export const deleteProduct = async (subCollectionNameCategory: string, productId: string) => {
    try {
        const q = query(collection(db, "shop", "products", subCollectionNameCategory), where("productId", "==", productId));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size === 0) {
            console.log("db.ts 'deleteProduct' : querySnapshot.size === 0 (no such document)");
            return null;
        } else if (querySnapshot.size !== 1) {
            console.log("db.ts 'deleteProduct' : querySnapshot.size > 1 (more than one document)");
            return null;
        } else {
            const docRef = querySnapshot.docs[0].ref;
            await deleteDoc(docRef);
            console.log("Document deleted with ID: ", docRef.id);
            return docRef.id;
        }
    } catch (error) {
        console.error("Error deleting document: ", error);
    }
}


//get hall of fame element
export const getHallOfFameElements = async () => {
    try {
        const colRef = collection(db, "hof");
        const docSnap = await getDocs(colRef);
        if (docSnap.docs.length > 0) {
            const data = docSnap.docs.map(doc => doc.data());
            return data as HOFElement[];
        } else {
            console.log("db.ts 'getHallOfFameElements': no hall of fame element was found");
            return null;
        }
    } catch (error) {
        console.error('Error fetching Subjects:', error);
    }
}

//get the firestore document id of the user with uid field = userId
export const getUserDocId = async (userId: string) => {
    const collectionRef = collection(db, "users");
    const q = query(collectionRef, 
        where("uid", "==", userId));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.size === 0) {
        console.log("db.ts 'getUserDocId' : querySnapshot.size === 0 (no such document)");
        return null;
    } else if (querySnapshot.size !== 1) {
        console.log("db.ts 'getUserDocId' : querySnapshot.size > 1 (more than one document)");
        return null;
    } else {
        return querySnapshot.docs[0].id;
    }
}

//get the document id of the product in shop/products/subCollectionNameCategory/documents that has the a field "productId" with value "productId"
export const getProductDocId = async (subCollectionNameCategory: string, productId: string) => {
    const collectionRef = collection(db, "shop", "products", subCollectionNameCategory);
    const q = query(collectionRef, 
        where("productId", "==", productId));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.size === 0) {
        console.log("db.ts 'getProductDocId' : querySnapshot.size === 0 (no such document)");
        return null;
    } else if (querySnapshot.size !== 1) {
        console.log("db.ts 'getProductDocId' : querySnapshot.size > 1 (more than one document)");
        return null;
    } else {
        return querySnapshot.docs[0].id;
    }
}

// get cart items from users/document where uid field = userId /subcollection cart / document with productId field = productId
export const getCartItemsOfUser = async (userId: string) => {
    try {
        const userDocId = await getUserDocId(userId);
        if (userDocId) {
            const colRef = collection(db, "users", userDocId, "cart");
            const docSnap = await getDocs(colRef);
            if (docSnap.docs.length >= 0) {
                const data = docSnap.docs.map(doc => doc.data());
                return data as CartItem[] | null;
            } else {
                console.log("db.ts 'getCartItemsOfUser': no cart item was found");
                return null;
            }
        } else {
            console.log("db.ts 'getCartItemsOfUser': no user was found");
            return null;
        }
    } catch (error) {
        console.error('Error fetching cart items:', error);
    }
}
//get all cart items of a user, and return them as a product object
export const getCartItems = async (userId: string) => {
    try {
        const userDocId = await getUserDocId(userId);
        if (userDocId) {
            const colRef = collection(db, "users", userDocId, "cart");
            const docSnap = await getDocs(colRef);
            if (docSnap.docs.length > 0) {
                for (const category of categories) {
                const data = docSnap.docs.map(doc => doc.data());
                return (data as CartItem[], getCartItemProductFromId(userId, category, data[0].productId));
                }
            } else {
                console.log("db.ts 'getCartItems': no cart item was found");
                return null;
            }
        } else {
            console.log("db.ts 'getCartItems': no user was found");
            return null;
        }
    } catch (error) {
        console.error('Error fetching cart items:', error);
    }
}

//getcartitemdocid
export const getCartItemDocId = async (userDocId: string, productId: string) => {
    const collectionRef = collection(db, "users", userDocId, "cart");
    const q = query(collectionRef, 
        where("productId", "==", productId));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.size === 0) {
        console.log("db.ts 'getCartItemDocId' : querySnapshot.size === 0 (no such document)");
        return null;
    } else if (querySnapshot.size !== 1) {
        console.log("db.ts 'getCartItemDocId' : querySnapshot.size > 1 (more than one document)");
        return null;
    } else {
        return querySnapshot.docs[0].id;
    }
}

//add a cart item to users/document where uid field = userId /subcollection cart / document with productId field = productId. product has to have a documentreference field named "productDocRef" with a reference to the product document in shop/products/subCollectionNameCategory/document with id = productDocId
//check if the product is already in the cart, if it is, update the quantity and size. if not, add it to the cart
export const addProductToCart = async (userId: string, category:string ,productId: string, quantity: number, productSize?:string) => {
    try {
        const userDocId = await getUserDocId(userId);
        if (userDocId) {
            const productDocId = await getProductDocId(category, productId);
            if (productDocId) {
                const cartItems = await getCartItemsOfUser(userId);
                if (cartItems) {
                    const cartItem = cartItems.find(cartItem => cartItem.productId === productId);
                    if (cartItem) {
                        const cartItemDocId = await getCartItemDocId(userDocId, productId);
                        if (cartItemDocId) {
                            const cartItemDocRef = doc(db, "users", userDocId, "cart", cartItemDocId);
                            await updateDoc(cartItemDocRef, {quantity: quantity, productSize: productSize});
                            console.log("Document updated with ID: ", cartItemDocId);
                            alert("Ürün sepete eklendi");
                            return cartItemDocId;
                        } else {
                            console.log("db.ts 'addProductToCart': no cart item was found");
                            return null;
                        }
                    } else {
                        const docRef = await addDoc(collection(db, "users", userDocId, "cart"), { quantity: quantity, productSize: productSize, productId: productId}); //removed productDocRef: doc(db, "shop", "products", category, productDocId) 
                        console.log("Document written with ID: ", docRef.id);
                        return docRef.id;
                    }
                }
            } else {
                console.log("db.ts 'addProductToCart': no product was found");
                return null;
            }
        } else {
            console.log("db.ts 'addProductToCart': no user was found");
            return null;
        }
    } catch (error) {
        console.error("Error adding document: ", error);
    }
}

//previous func but takes only uid and product object as parameters
export const addProductToCart2 = async (userId: string, product: Product) => {
    try {
        const userDocId = await getUserDocId(userId);
        if (userDocId) {
            const productDocId = await getProductDocId(product.category, product.productId);
            if (productDocId) {
                const cartItems = await getCartItemsOfUser(userId);
                if (cartItems) {
                    const cartItem = cartItems.find(cartItem => cartItem.productId === product.productId);
                    if (cartItem) {
                        const cartItemDocId = await getCartItemDocId(userDocId, product.productId);
                        if (cartItemDocId) {
                            const cartItemDocRef = doc(db, "users", userDocId, "cart", cartItemDocId);
                            await updateDoc(cartItemDocRef, {quantity: cartItem.quantity + 1});
                            console.log("Document updated with ID: ", cartItemDocId);
                            return cartItemDocId;
                        } else {
                            console.log("db.ts 'addProductToCart': no cart item was found");
                            return null;
                        }
                    } else {
                        const docRef = await addDoc(collection(db, "users", userDocId, "cart"), { quantity: 1, productId: product.productId}); //removed productDocRef: doc(db, "shop", "products", category, productDocId) 
                        console.log("Document written with ID: ", docRef.id);
                        return docRef.id;
                    }
                }
            } else {
                console.log("db.ts 'addProductToCart': no product was found");
                return null;
            }
        } else {
            console.log("db.ts 'addProductToCart': no user was found");
            return null;
        }
    } catch (error) {
        console.error("Error adding document: ", error);
    }
}

//for later func 
export const getCartItemProductFromId = async (userId: string, category:string ,productId: string) => {
    try {
        const userDocId = await getUserDocId(userId);
        if (userDocId) {
            const productDocId = await getProductDocId(category, productId);
            if (productDocId) {
                const product = await getProduct(category, productId);
                if (product) {
                    return product;
                } else {
                    console.log("db.ts 'getCartItemProductFromId': no product was found");
                    return null;
                }
            } else {
                console.log("db.ts 'getCartItemProductFromId': no product was found");
                return null;
            }
        } else {
            console.log("db.ts 'getCartItemProductFromId': no user was found");
            return null;
        }
    } catch (error) {
        console.error('Error fetching cart items:', error);
    }
};

export const getAllCartItemProductsOfAllCategoriesOfUser = async (userId: string) => {
    try {
        const userDocId = await getUserDocId(userId);
        if (userDocId) {
            const colRef = collection(db, "users", userDocId, "cart");
            const docSnap = await getDocs(colRef);
            if (docSnap.docs.length > 0) {
                var products: Product[] = [];
                for (const category of categories){
                    for (const doc of docSnap.docs) {
                        const product = await getProduct(category, doc.data().productId);
                        if (product) {
                            products.push(product);
                        } else {
                            console.log("db.ts 'getAllCartItemProductsOfAllCategoriesOfUser': no product was found");
                            // return null;
                        }
                        
                    }
                }return products as Product[];
            } else {
                console.log("db.ts 'getAllCartItemProductsOfAllCategoriesOfUser': no cart item was found");
                return null;
            }
        } else {
            console.log("db.ts 'getAllCartItemProductsOfAllCategoriesOfUser': no user was found");
            return null;
        }
    } catch (error) {
        console.error('Error fetching cart items:', error);
    }
}

//update cart item in users/document where uid field = userId /subcollection cart / document with productId field = productId
export const updateCartItem = async (userId: string, productId: string, newQuantity: number) => {
    try {
        const userDocId = await getUserDocId(userId);
        if (userDocId) {
            const cartItemDocId = await getCartItemDocId(userDocId, productId);
            if (cartItemDocId) {
                const cartItemDocRef = doc(db, "users", userDocId, "cart", cartItemDocId);
                await updateDoc(cartItemDocRef, {quantity: newQuantity});
                console.log("Document updated with ID: ", cartItemDocId);
                return cartItemDocId;
            } else {
                console.log("db.ts 'updateCartItem': no cart item was found");
                return null;
            }
        } else {
            console.log("db.ts 'updateCartItem': no user was found");
            return null;
        }
    } catch (error) {
        console.error("Error updating document: ", error);
    }
}

//delete cart item from users/document where uid field = userId /subcollection cart / document with productId field = productId
export const deleteCartItem = async (userId: string, productId: string) => {
    try {
        const userDocId = await getUserDocId(userId);
        if (userDocId) {
            const cartItemDocId = await getCartItemDocId(userDocId, productId);
            if (cartItemDocId) {
                const cartItemDocRef = doc(db, "users", userDocId, "cart", cartItemDocId);
                await deleteDoc(cartItemDocRef);
                console.log("Document deleted with ID: ", cartItemDocId);
                return cartItemDocId;
            } else {
                console.log("db.ts 'deleteCartItem': no cart item was found");
                return null;
            }
        } else {
            console.log("db.ts 'deleteCartItem': no user was found");
            return null;
        }
    } catch (error) {
        console.error("Error deleting document: ", error);
    }
}