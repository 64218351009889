import React, { useEffect, useState } from 'react';
import { User } from 'firebase/auth';
import { Route, Routes, useLocation } from 'react-router-dom';
import NotFoundPage from '../../common-pages/notFoundPage';
import { addProductToCart, getProduct } from '../../firebase/firestore/db';
import { Product } from '../../firebase/firestore/firestore-types';
import '../../styles-common/productModule.css';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useAuth } from '../../contexts/authContext';

export interface ProductProps {
  user: User | null;
}

const ProductPage = (props: ProductProps) => {
  const user = useAuth();
  var [userUid, setUserUid] = useState<string | undefined>(user?.uid as string | ""); //for now
  const [product, setProduct] = useState<Product | null>(null);
  const currentPath = useLocation().pathname;
  const productCategoryFromPath = currentPath.split("/")[2] as string;
  const productIdFromPath = decodeURIComponent(currentPath.split("/")[3]);
  console.log("productCategoryFromPath: ", productCategoryFromPath);
  console.log("productIdFromPath: ", productIdFromPath);
  const [selectedSize, setSelectedSize] = useState<string>("");
  const [quantity, setQuantity] = useState(1);
  const [currentIndexImage, setCurrentIndexImage] = useState(0);
  const handleGetProduct = async (productCategory: string, productId: string) => {
    await getProduct(productCategory, productId).then((product) => {
      console.log(product);
      setProduct(product as Product | null);
      return product;
    });

  };

  useEffect(() => {
    handleGetProduct(productCategoryFromPath, productIdFromPath);
  }, [productCategoryFromPath, productIdFromPath]);

  //add to cart
  const handleAddToCart = async () => {
    if (product) {
      if (user) {
        userUid = (user?.uid as string);
        await addProductToCart(userUid, productCategoryFromPath, product.productId, quantity, selectedSize);
        console.log(`Added ${quantity} ${selectedSize} units of ${product.productName} to cart.`);
      } else {
        alert("Please sign in to add products to cart.");
      }
    }
  };

  // image gallery gpt

  const handlePreviewClick = (index: number) => {
    setCurrentIndexImage(index);
  };

  useEffect(() => {
    // const imagesContainer = document.querySelector('.images-container');
    const imagePreviews = document.querySelectorAll('.image-preview');

    async function updateSelectedUI() {
      imagePreviews.forEach((preview, index) => {
        preview.addEventListener('click', () => {
          setCurrentIndexImage(index);
          updateSelectedUI();
        });
      });
      imagePreviews.forEach((preview, index) => {
        preview.classList.toggle('selected', index === currentIndexImage);

      });
    }


    setCurrentIndexImage(currentIndexImage);

    console.log("66 : currentIndexImage : ", currentIndexImage);
    updateSelectedUI();
  }, [currentIndexImage]);

  useEffect(() => {
    setUserUid(user?.uid as string);
    console.log("userUid: ", userUid);
  }, [user, []]);

  useEffect(() => {
    if (productCategoryFromPath === "giyim") {
      setSelectedSize("s");
    }
  }, []);

  if (productIdFromPath !== undefined && product !== null) {
    return (
      <div className="product-module-wrapper">
        Product Category: {productCategoryFromPath}
        Product ID: {productIdFromPath}
        <div className="product-detail-productpage">

          {/* incoming paste */}
          <div className="product-images">
            <div className="gallery-container">
              <button
                className="leftarrow"
                style={{ display: "contents" }}
                onClick={() => setCurrentIndexImage(currentIndexImage - 1)}
                disabled={currentIndexImage === 0}
              > <KeyboardArrowLeftIcon />
              </button>
              <div className="images-container">
                {product &&

                  <img src={product?.imageUrls && product.imageUrls[currentIndexImage]} alt={"product-img-" + { currentIndexImage }} />}
              </div>
              <button
                className="rightarrow"
                style={{ display: "contents" }}
                onClick={() => setCurrentIndexImage(currentIndexImage + 1)}
                disabled={currentIndexImage === product?.imageUrls?.length! - 1}
              > <KeyboardArrowRightIcon />
              </button>
            </div>
            <div className="image-previews">
              {product?.imageUrls?.map((imageUrl, index) => (
                <div
                  key={index}
                  className={`image-preview ${index === currentIndexImage ? "selected" : ""}`}
                  onClick={() => handlePreviewClick(index)}
                >
                  <img src={imageUrl} alt={`Product preview${index}`} />
                </div>
              ))}
            </div>
          </div>
          <div className="product-info-productpage">
            <h1>{product?.productName}</h1>
            <p className="product-description-productpage">{product?.description}</p>
            <div className="product-rating-productpage">
              <span>Rating: {product?.averageRating && product?.averageRating?.toFixed(1)}/5 </span>
              <span>({product?.ratings?.length} ratings)</span>
            </div>
            {product?.productSizes && product?.productSizes?.length > 0 && <div><h3>Available Sizes:</h3>
              <div className="product-size-productpage">
                <label>Select Size:</label>
                <select
                  value={selectedSize}
                  onChange={(e) => setSelectedSize(e.target.value)}
                >
                  {product?.productSizes.map((size, index) => (
                    <option key={index} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>
            </div>}

            <div className="product-quantity-productpage">
              <label>Quantity:</label>
              <input
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(Number(e.target.value))}
              />
            </div>
            <div className="product-price-productpage">
              <span>Price: ₺{product.pricePerUnitTry}</span>
            </div>
            <button onClick={handleAddToCart} className="add-to-cart-button">
              Add to Cart <AddShoppingCartIcon />
            </button>
            {/* comments */}
            <div className="product-comments">
              <h3>Customer comments:</h3>
              {product?.comments && product?.comments.map((comment: string, index: number) => {
                return (
                  <p className="product-comment-paragraph" key={index}>{comment}</p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Routes>
        <Route path="*" element={<NotFoundPage user={props.user} />} />
      </Routes>
    )
  }
};

export default ProductPage;
