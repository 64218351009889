import react from 'react';
import { User } from 'firebase/auth';
import '../../styles-common/checkout.css';

export interface CheckoutProps {
    user: User | null;
}

export default function CheckoutPage(props: CheckoutProps) {
    return (
        <div className="checkout-page-wrapper">
        <h1>Checkout</h1>
        </div>
    );
}