import { getAllProducts , addProductToCart} from "../../firebase/firestore/db";
import { useState, useMemo } from "react";
import { Product } from "../../firebase/firestore/firestore-types";
import { Helmet } from 'react-helmet';
//css
import '../../styles-common/copypasta/bootstrap.css';
import '../../styles-common/copypasta/style.css';
import '../../styles-common/copypasta/responsive.css';
//import css from a https url
export default function Products(props: { user: any }) {
    const [products, setProducts] = useState<Product[]>([]);
    const handleGetProducts = async () => {
        const products = await getAllProducts() as Product[];
        setProducts(products);
        console.log(products);
    };
    useMemo(() => {
        handleGetProducts();
    }, []);
    return (
        <div className="all-products-wrapper">
            <Helmet>
                <title>Ürünler</title>
                {/* <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"/> */}
            </Helmet>
            {/* <div className="w3-padding-64 w3-large w3-text-grey">
                <a href="#" className="w3-bar-item w3-button">Shirts</a>
                <a href="#" className="w3-bar-item w3-button">Dresses</a>
                <a onClick={()=>console.log("sth")} href="javascript:void(0)" className="w3-button w3-block w3-white w3-left-align" id="myBtn">
                Jeans <i className="fa fa-caret-down"></i>
                </a>
                <div id="demoAcc" className="w3-bar-block w3-hide w3-padding-large w3-medium w3-show">
                <a href="#" className="w3-bar-item w3-button w3-light-grey"><i className="fa fa-caret-right w3-margin-right"></i>Skinny</a>
                <a href="#" className="w3-bar-item w3-button">Relaxed</a>
                <a href="#" className="w3-bar-item w3-button">Bootcut</a>
                <a href="#" className="w3-bar-item w3-button">Straight</a>
                </div>
                <a href="#" className="w3-bar-item w3-button">Jackets</a>
                <a href="#" className="w3-bar-item w3-button">Gymwear</a>
                <a href="#" className="w3-bar-item w3-button">Blazers</a>
                <a href="#" className="w3-bar-item w3-button">Shoes</a>
            </div> */}
            <section className="product_section layout_padding">
                
                <div className="container">
                    
                    <div className="heading_container heading_center">
                        <h2>
                            Amcı <span>Ürünleri</span>
                        </h2>
                    </div>
                    <div className="row">
                        {products ? products.map((product, index) => {
                            return (
                                <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                                    <div className="box">
                                        <div className="option_container">
                                            <div className="options">
                                                <a style={{cursor:"pointer"}} onClick={()=> addProductToCart(props.user?.uid as string, product.category, product.productId, 1, "")} className="option1">
                                                    Sepete Ekle
                                                </a>
                                                <a href={"/product/" + product.category + "/" + product.productId} className="option2">
                                                    İncele
                                                </a>
                                            </div>
                                        </div>
                                        <div className="img-box">
                                            <img src={product.imageUrls?.length > 0 ? product.imageUrls[0] : ""} alt="" />
                                        </div>
                                        <div className="detail-box">
                                            <h5>
                                                {product.productName}
                                            </h5>
                                            <h6>
                                                ₺{product.pricePerUnitTry}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : <>Ürünleri Yükleniyor...</>}

                    </div>
                    <div className="btn-box">
                        <a href="">
                            Daha Fazla...
                        </a>
                    </div>
                </div>
            </section>


        </div>
    )
}