import react, {useEffect, useState, useContext, createContext, ReactNode} from 'react';
import { User , onAuthStateChanged , getAuth} from 'firebase/auth';
import { AuthProps } from '../common-pages/auth/auth';
import { app } from '../configs/firebaseConfig';
export const AuthContext = createContext<User | null>(null);

export const auth = getAuth(app);

export const AuthProvider = ({children}:{children:ReactNode}) => {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setUser(user);
        })
    }, []);
    return (
        <AuthContext.Provider value={user}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};