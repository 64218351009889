import {useState} from 'react';
import useScript from '../../hooks/useScript';
export const Header = () => {
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState<boolean>(true);
    const [isNavbarCollapsing, setIsNavbarCollapsing] = useState<boolean>(false);
    //collabse navbar delay is 0.2s
    const collapseNavBar = () => {
        setIsNavbarCollapsing(true);
        setTimeout(() => {
            setIsNavbarCollapsing(false);
        }, 200);
    }
    return (
    <header className="header_section" style={{borderBottom: "1px solid #007bff31"}}>
        <div className="container">
            <nav className="navbar navbar-expand-lg custom_nav-container ">
                <a className="navbar-brand" href="https://merch.amcilar.com/"><img style={{ maxWidth: "20vw" }} src="https://amcilar.com/img/amcilar-logo.png" alt="shop-logo-top-left" /> </a> <span className="sitenametext">Amcılar</span>
                <button onClick={()=> {setIsNavbarCollapsed(!isNavbarCollapsed); collapseNavBar()}} className={`navbar-toggler ${isNavbarCollapsed ? "collapsed" : ""}`} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={!isNavbarCollapsed} aria-label="Toggle navigation">
                    <span className=""> </span>
                </button>
                <div className={`collapse navbar-collapse ${ isNavbarCollapsing ? "collapsing" : isNavbarCollapsed ? "collapsed" : "show"}`} id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="https://home.amcilar.com/"> Anasayfa </a>
                            {/* <span className="sr-only">(current)</span> */}
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link" href="https://amcilar.com/pages/about">Hakkımızda</a>
                            </li> */}
                        <li className="nav-item">
                            <a className="nav-link" href="https://merch.amcilar.com/products">Ürünler</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://merch.amcilar.com/cart">
                                Sepet <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 456.029 456.029">
                                 <g>
                                    <g>
                                       <path d="M345.6,338.862c-29.184,0-53.248,23.552-53.248,53.248c0,29.184,23.552,53.248,53.248,53.248
                                          c29.184,0,53.248-23.552,53.248-53.248C398.336,362.926,374.784,338.862,345.6,338.862z"></path>
                                    </g>
                                 </g>
                                 <g>
                                    <g>
                                       <path d="M439.296,84.91c-1.024,0-2.56-0.512-4.096-0.512H112.64l-5.12-34.304C104.448,27.566,84.992,10.67,61.952,10.67H20.48
                                          C9.216,10.67,0,19.886,0,31.15c0,11.264,9.216,20.48,20.48,20.48h41.472c2.56,0,4.608,2.048,5.12,4.608l31.744,216.064
                                          c4.096,27.136,27.648,47.616,55.296,47.616h212.992c26.624,0,49.664-18.944,55.296-45.056l33.28-166.4
                                          C457.728,97.71,450.56,86.958,439.296,84.91z"></path>
                                    </g>
                                 </g>
                                 <g>
                                    <g>
                                       <path d="M215.04,389.55c-1.024-28.16-24.576-50.688-52.736-50.688c-29.696,1.536-52.224,26.112-51.2,55.296
                                          c1.024,28.16,24.064,50.688,52.224,50.688h1.024C193.536,443.31,216.576,418.734,215.04,389.55z"></path>
                                    </g>
                                 </g>
                                 <g>
                                 </g>
                                 <g>
                                 </g>
                                 <g>
                                 </g>
                                 <g>
                                 </g>
                                 <g>
                                 </g>
                                 <g>
                                 </g>
                                 <g>
                                 </g>
                                 <g>
                                 </g>
                                 <g>
                                 </g>
                                 <g>
                                 </g>
                                 <g>
                                 </g>
                                 <g>
                                 </g>
                                 <g>
                                 </g>
                                 <g>
                                 </g>
                                 <g>
                                 </g>
                              </svg>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/auth">Giriş</a>
                        </li>

                        <form className="form-inline">
                            <button className="btn  my-2 my-sm-0 nav_search-btn" type="submit">
                                <i className="fa fa-search" aria-hidden="true"></i>
                            </button>
                        </form>
                    </ul>
                </div>
            </nav>
        </div>
    </header>
    )
}