import React from "react";
import { User } from "firebase/auth";
import { Routes, Route } from "react-router-dom";
import ShopPage, { ShopProps } from "../subdomains/shop/shop";
import HomePage from "../subdomains/home/home";
import "../styles-common/notFoundPage.css";

const NotFoundPage = (props: ShopProps) => {
    return (
        <div className="not-found-page-wrapper">
        <h1>404 Not Found</h1>
        </div>
    );
};

export default NotFoundPage;