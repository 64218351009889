export const GoodFooter = () => {

    return (
        <footer className="footer_section">
            <div className="container">
              <div className="row">
                <div className="col-md-4 footer-col">
                  <div className="footer_contact">
                    <h4>
                      Bize ulaş
                    </h4>
                    <div className="contact_link_box">
                      {/* <a href="">
                          <i className="fa fa-map-marker" aria-hidden="true"></i>
                          <span>
                          Location
                          </span>
                          </a> */}
                      <a href="">
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        <span>
                          Whatsapp : +01 1234567890
                        </span>
                      </a>
                      <a href="mailto:efecalidag@gmail.com">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                        <span>
                          Mail : efecalidag@gmail.com
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 footer-col">
                  <div className="footer_detail">
                    <a href="https://amcilar.com/" className="footer-logo">
                      Amcılar
                    </a>
                    <p>
                      Amcılığa doğru kendinizden emin adımlarla ilerleyin. Alışverişinizi bizimle yapın.
                    </p>
                    <div className="footer_social">
                      <a href="">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                      <a href="">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                      <a href="">
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                      <a href="">
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                      <a href="">
                        <i className="fa fa-pinterest" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 footer-col">
                  <div className="footer_contact">
                    <h4>
                      Navigasyon
                    </h4>
                    <div className="contact_link_box">
                      <a href="https://merch.amcilar.com/">
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        <span>
                          Alışveriş Yap
                        </span>
                      </a>
                      <a href="https://merch.amcilar.com/cart">
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        <span>
                          Alışveriş Sepetiniz
                        </span>
                      </a>
                      <a href="https://home.amcilar.com/">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                        <span>
                          Ana sayfa
                        </span>
                      </a>
                      <a href="https://amcilar.com/pages/about">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                        <span>
                          Hakkımızda
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-info">
                <div className="col-lg-7 mx-auto px-0">
                  <p>
                    &copy; <span id="displayYear">2023 - {new Date().getFullYear()}</span> All Rights Reserved By
                    <a href="https://amcilar.com/"> Amcılar</a>
                  </p>
                </div>
              </div>
            </div>
          </footer>
    )
}